import { useEffect, useState } from "react";
import api from './api';
import formHelper from "./helpers/form";
import Modal from "./Modal";
import formatMoney from "./helpers/formatMoney";
import { createPortal } from "react-dom";

const Widget = props => {
    const [product, setProduct] = useState(null);
    const [oneTime, setOneTime] = useState(false);
    const [sellingPlans, setSellingPlans] = useState([]);
    const [variantId, setVariantId] = useState(null);
    const [sellingPlan, setSellingPlan] = useState(null);
    const [formId, setFormId] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const setSellingPlansList = variant_id => {
        if ( !product ) {
            setSellingPlans([]); 
            return; 
        }

        const variant = product.variants.find( v => v.id == variant_id ); 
        if ( variant && variant.selling_plan_groups ) {
            setSellingPlans(variant.selling_plan_groups.map( group =>  group.selling_plans ).flat())
        }
    }

    useEffect( () => {

        // // Here we pull the initial variant id from form. This might need some override 
        // // in some scenarios. 
        const form = formHelper.getForm(props.element);

        if ( form ) {
            const formId = form?.attributes?.id; 

            if (formId) {
                setFormId(formId.value);
            }

            let input = form.querySelector('[name=id]');
            
            // Sometimes we won't get the input from within the form, so we have to look for outside 
            // to try to find it. 
            if (!input && formId) {
                input = document.querySelector(`[name=id][form="${formId.value}"]`);
            }

            setVariantId(input.value);
            input.addEventListener('change', (event) => {
                setVariantId( event.target.value );
            }); 
        }

        fetch(`/products/${props.options.handle}.js`)
            .then(res => res.json() )
            .then( res => {

                if ( res.selling_plan_groups == null || res.selling_plan_groups.length < 1 ) {
                    return; 
                }

                const payload = {
                    id: res.id,
                    handle: res.handle,
                    money_format: props.options.moneyFormat,
                    money_with_currency_format: props.options.moneyFormatWithCurrency,
                    selling_plan_groups: res.selling_plan_groups.filter( group => group.app_id == 'qpilot'),
                    requires_selling_plan: res.requires_selling_plan,
                    variants: res.variants.map(variant => {
                        // Here we need to find the variant group
                        const variantGroup = res.selling_plan_groups.find( group => {
                            return group.app_id == 'qpilot'
                             && variant.selling_plan_allocations.some(allocation =>
                                allocation.selling_plan_group_id === group.id
                            )
                        });

                        return {
                            id: variant.id, 
                            price: variant.price * 0.01, 
                            price_sale: variant.selling_plan_allocations.length > 0 
                                ? variant.selling_plan_allocations[0].price * 0.01
                                : variant.price * 0.01,
                            selling_plan_groups: variant.selling_plan_allocations.length > 0 ? [
                                {
                                    id: variantGroup.id,
                                    name: variantGroup.name,
                                    selling_plans: variant.selling_plan_allocations.filter( allocation => 
                                        allocation.selling_plan_group_id === variantGroup.id
                                    ).map( plan => {
                                        const mainplan = variantGroup.selling_plans.find(p => p.id === plan.selling_plan_id);
                                        
                                        return {
                                            id: plan.selling_plan_id,
                                            name: mainplan.name,
                                            discount: mainplan.price_adjustments && mainplan.price_adjustments.length > 0
                                                ? (mainplan.price_adjustments[0].value_type === 'percentage'
                                                    ? mainplan.price_adjustments[0].value
                                                    : mainplan.price_adjustments[0].value * 0.01)
                                                : 0,
                                            discount_type: mainplan?.price_adjustments?.[0]?.value_type ?? 'percentage',
                                            interval: mainplan.options[0].value.split(' ').pop(),
                                            interval_count: mainplan.options[0].value.split(' ').shift(),
                                        }      
                                    })
                                }
                            ] : []
                        }
                    }),
                }

                setProduct(payload)
                setSellingPlansList(variantId);

                if ( !payload.requires_selling_plan && !props.options.subscribeFirst ) {
                    setOneTime(true);
                }
            });

        /**
         * This is an event listener to update the variant id when the variant changes. 
         * It's a way to manually override the variant id in custom scenarios. 
         */
        const handleVariantUpdate = (event) => {
            if ( event?.detail?.variantId ) {
                setVariantId(event.detail.variantId);
            }
        }
        document.addEventListener('autoship:updatevariant', handleVariantUpdate);
        
        // Clean up by removing event listener when component unmounts
        return () => {
            document.removeEventListener('autoship:updatevariant', handleVariantUpdate);
        };
    }, []);

    useEffect( () => {
        setSellingPlansList(variantId);
    }, [product, variantId]); 

    useEffect( () => {
        // This works in case there's no selling plan assigned. 
        if ( !sellingPlan && sellingPlans.length > 0 ) {
            setSellingPlan(sellingPlans[0].id)
        }

        // this reasigns if selling plan IDs change. 
        if ( sellingPlan && sellingPlans.length > 0 && !sellingPlans.some( p => p.id == sellingPlan )) {
            setSellingPlan(sellingPlans[0].id)
        }
    }, [sellingPlans])

    // We use this to dispatch events outside of this container. 
    useEffect( () => {
        const plan = currentSellingPlan();
        if (!variantId || !product || !plan) return;

        const variant = product?.variants?.find(v => v.id == variantId); 
        
        const data = {
            selling_plan_id: oneTime ? null : (plan.id || null),
            price: oneTime ? variant.price : variant.price_sale,
            frequency: plan.interval_count || null,
            frequency_interval: plan.interval || null,
        }

        const event = new CustomEvent('selling-plan:change', {detail: data});
        document.dispatchEvent(event);
        
    }, [product, sellingPlan, variantId, oneTime])


    useEffect(() => {
        if ( showModal ) {
            document.body.classList.add('autoship-freeze');
        } else {
            document.body.classList.remove('autoship-freeze');
        }
    }, [showModal]);


    const currentSellingPlan = () => {
        return sellingPlans.find(plan => plan.id == sellingPlan) || null; 
    }

    const currentSellingPlanFrequency = () => {
        const plan = currentSellingPlan();

        return `${plan.interval_count} ${plan.interval}`;
    }

    const currentSellingPlanFrequencyValue = () => {
        const plan = currentSellingPlan();
        return plan.interval_count;
    }

    const currentSellingPlanFrequencyInterval = () => {
        const plan = currentSellingPlan();
        return plan.interval;
    }

    const currentVariantPrice = () => {
        const variant = product.variants.find(v => v.id == variantId); 
        return formatMoney(variant.price * 100.0, product.money_format).replace('.00', ''); 
    }

    const currentDiscountedPrice = () => {
        const variant = product.variants.find(v => v.id == variantId); 
        return formatMoney(variant.price_sale * 100.0, product.money_format).replace('.00', ''); 
    }

    /**
     * Get the selling plan discount depending on the format in the backend.
     * @returns 
     */
    const currentSellingPlanDiscount = () => {
        const plan = currentSellingPlan(); 
        if (!plan) return '';

        switch( plan.discount_type ) {
            case 'percentage': 
                return plan.discount.toString() + '%'
            case 'fixed_amount': 
                return formatMoney(plan.discount * 100.0, product.money_format); 
            case 'price': 
                return formatMoney(plan.discount * 100.0, product.money_format); 
        } 
    }

    /**
     * Get the selling plan discount in currency.
     * @returns 
     */
    const currentSellingPlanDiscountMoney = () => {
        const plan = currentSellingPlan(); 
        if (!plan) return '';

        const variant = product.variants.find(v => v.id == variantId); 
        if (!variant) return ''; 

        return formatMoney((variant.price - variant.price_sale) * 100.0, product.money_format).replace('.00', ''); 
    }

    const subscriptionDiscountedPrice = () => {
        const variant = product.variants.find(v => v.id == variantId); 
        if (!variant) return ''; 

        const plan = currentSellingPlan(); 
        if (!plan) return '';

        if (variant.price_sale) {
            if (plan.discount === 0) {
                return ' and future orders'; 
            }

            switch( plan.discount_type ) {
                case 'percentage': 
                    return ' and ' + plan.discount.toString() + '% on future orders' 
                case 'fixed_amount': 
                    return ' and ' + formatMoney((variant.price - variant.price_sale) * 100.0, product.money_format) + ' on future orders'; 
                case 'price': 
                    return ' and ' + formatMoney(variant.price_sale * 100.0, product.money_format) + ' on future orders'; 
            }
        }

        return ''; 
    }

    const buyOnceLabel = () => {
        if ( props.options?.buyOnceLabel ) {
            return (
                <span className="autoship-widget-dropdown-label__text" dangerouslySetInnerHTML={
                    {__html: props.options.buyOnceLabel.replace('{price}', currentVariantPrice()) }
                }>
                </span>
            );
        }

        return 'Buy it once';
    }

    const subscriptionsLabel = () => {
        const plan = currentSellingPlan(); 
        if (!plan) return '';

        if ( props.options.subscribeLabel ) {
            return (
                <span className="autoship-widget-dropdown-label__text" dangerouslySetInnerHTML={
                    {__html: props.options.subscribeLabel
                        .replace('{discount}', currentSellingPlanDiscount())
                        .replace('{discount_money}', currentSellingPlanDiscountMoney())
                        .replace('{price}', currentVariantPrice())
                        .replace('{discounted_price}', currentDiscountedPrice())
                        .replace('{frequency}', currentSellingPlanFrequency())
                        .replace('{frequency_value}', currentSellingPlanFrequencyValue())
                        .replace('{frequency_interval}', currentSellingPlanFrequencyInterval())
                    }
                }>
                </span>
            );
        }

        if ( plan.discount === 0 ) {
            return `Subscribe for ${currentVariantPrice()}`; 
        }

        switch( plan.discount_type ) {
            case 'percentage': 
                return `Subscribe and save ${currentSellingPlanDiscount()} at checkout ${subscriptionDiscountedPrice()}.`; 
            case 'fixed_amount': 
                return `Subscribe and save ${currentSellingPlanDiscount()} at checkout ${subscriptionDiscountedPrice()}.`; 
            case 'price': 
                return `Subscribe for ${currentSellingPlanDiscount()} at checkout ${subscriptionDiscountedPrice()}.`;
        }
    }

    return (
        <div>
            {product && sellingPlans.length > 0 && (
                <>
                    { !product.requires_selling_plan && (
                        <div className={`autoship-widget-option-wrapper noborder ${oneTime ? 'option-active' : ''}`}>
                            <label className="autoship-widget-dropdown-label">
                                <input type="radio" name="one_time" checked={oneTime} onChange={() => {
                                    setOneTime(true)
                                }} /> 

                                {buyOnceLabel()}
                            </label>
                        </div>
                    )}

                    <div className={`autoship-widget-option-wrapper vertical ${!oneTime ? 'option-active' : ''}`}>
                        <label className="autoship-widget-dropdown-label">
                            { !product.requires_selling_plan && (
                                    <input type="radio" name="one_time" checked={!oneTime} onChange={() => {
                                        setOneTime(false); 
                                        if ( !sellingPlan ) setSellingPlan(sellingPlans[0].id); 
                                    }} />
                            )}

                            {subscriptionsLabel()}
                            {!props.options.hideModal && (
                                <a href="#" onClick={(event) => { event.preventDefault(); setShowModal(true)}}>{ props.options?.whySubscribeModalLinkText }</a>
                            )}
                        </label>
                        {!oneTime && (
                            <div className="autoship-deliver-every-dropdown">
                                <select className="autoship-widget-dropdown" name="choose_selling_plan" onChange={
                                    (event) => {
                                        setOneTime(false);
                                        setSellingPlan(event.target.value); 
                                    }
                                }>
                                    { sellingPlans.map( plan => (
                                        <option key={plan.id} value={plan.id} selected={sellingPlan == plan.id}>{ plan.name }</option>
                                    ))
                                    }
                                </select>
                            </div>
                        )}

                        {/* This is to show some text or arbitrary html below the widget. */}
                        {!oneTime && props.options.subscribeFooter && currentSellingPlan() && (
                            <div className="autoship-widget-option-footer"
                                dangerouslySetInnerHTML={
                                    {__html: props.options.subscribeFooter
                                        .replace('{discount}', currentSellingPlanDiscount())
                                        .replace('{discount_money}', currentSellingPlanDiscountMoney())
                                        .replace('{price}', currentVariantPrice())
                                        .replace('{discounted_price}', currentDiscountedPrice())
                                        .replace('{frequency}', currentSellingPlanFrequency())
                                        .replace('{frequency_value}', currentSellingPlanFrequencyValue())
                                        .replace('{frequency_interval}', currentSellingPlanFrequencyInterval())
                                    }
                            }></div>
                        )}

                        { !oneTime && sellingPlan && (
                            <input type="hidden" name="selling_plan" value={sellingPlan} form={formId} />
                        )}
                    </div>
                </>
            )}

            {createPortal(
                <>
                    {showModal && <Modal title={props.options?.whySubscribeModalTitle} html={props.options?.whySubscribeModalHtml} onCloseModal={event => setShowModal(false)} />}
                </>,
                document.body
            )}
        </div>
    )
}

export default Widget; 

