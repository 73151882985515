export default {
    getForm(element) {
        // This is basically the dawn case.
        const forms = document.querySelectorAll(`form[action^="/cart/add"]:not(.installment):not([id^="product-installment-form-template"])`);
        if (forms.length > 0 && (
            window.location.href.includes('/products/')
            || window.location.href.includes('/products_preview')
        )) {
            return forms[0];
        }

        if (element.closest('form')) {
            return element.closest('form'); 
        } 

        return null; 
    }
}