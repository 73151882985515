import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Widget from './Widget'
import bindings from './bindings';

// first we start our bindings to make sure we have the element we need. 
bindings();

// We can load a widget instance in all autoship-widget elements.
const elements = document.querySelectorAll('.qpilot-widget, .autoship-widget'); 
for (let i = 0; i < elements.length; i++) {
  // Here we could pull some properties, and use them to fetch products. 
  const el = elements[i];

  const options = {
    handle: el.dataset.handle || null,
    buyOnceLabel: el.dataset.buyOnceLabel || null,
    subscribeLabel: el.dataset.subscribeLabel || null,
    subscribeFooter: el.dataset.subscribeFooter || null,
    subscribeFirst: el.dataset.subscribeFirst === 'true' || false,
    moneyFormat: el.dataset.moneyFormat || '${{ amount }}',
    moneyFormatWithCurrency: el.dataset.moneyFormatWithCurrency || '${{amount}} USD',
    hideModal: el.dataset.hideModal === 'true' || false,
    whySubscribeModalLinkText: el.dataset.whySubscribeModalLinkText || `Why Subscribe?`,
    whySubscribeModalTitle: el.dataset.whySubscribeModalTitle || `Why Subscribe?`,
    whySubscribeModalHtml: el.dataset.whySubscribeModalHtml || null,
  }
  ReactDOM.createRoot(el).render(<React.StrictMode><Widget options={options} element={el} /></React.StrictMode>)
}
