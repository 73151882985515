/**
 * This method creates the bindings for the PDP, and the PDP form. 
 */
const form_selectors = '[action="/cart/add"]:not(#product-form-installment):not(.installment):not([id*="installment"]):not([id*="instalment"])';

export default () => {
    const elements = document.querySelectorAll('.autoship-widget, .qpilot-widget'); 

    if ( elements.length > 0 ) {
        return; 
    }

    // IF it's the product's page. 
    if ( window.location.href.includes('/products/') ) {
        let form = document.querySelectorAll(form_selectors)[0] || false;

        if ( form && form.querySelector('[name=selling_plan]') ) {
            return;
        }

        // Creating the element
        let handle = window.location.href.split('/products/')[1].split('?')[0];
        let widget = document.createElement('div'); 
        widget.setAttribute('class', 'autoship-widget'); 
        widget.setAttribute('data-handle', handle); 

        // Dawn theme, above the form buttons. 
        if ( form && form.getElementsByClassName('product-form__buttons').length > 0 ) {
            const node = form.getElementsByClassName('product-form__buttons')[0]; 
            node.parentNode.insertBefore(widget, node); 
            return; 
        }

        // We just insert before the submit button.
        if ( form ) {
            const submitButton = form.querySelector('input[type=submit], button[type=submit]');
            // If querySelector returns null, we don't proceed
            if (submitButton) {
                submitButton.parentNode.insertBefore(widget, submitButton);
                return;
            }
        }
    }
}