const Modal = (props) => {

    const closeModal = (event) => {
        event.preventDefault();
        event.stopPropagation(); 
        props.onCloseModal(event); 
    }

    return (
        <div className="autoship-modal-overlay" onClick={closeModal}>
            <div className="autoship-modal-inner" onClick={event => event.stopPropagation()}>
                <span onClick={closeModal} className="autoship-modal-close">&times;</span>
                <h3>{ props.title }</h3>

                { props?.html ? (
                    <div dangerouslySetInnerHTML={{
                        __html: props.html
                    }}></div>
                ) : (
                    <ul>
                        <li>Never run out of your favorite products.</li>
                        <li>Discounted prices only for subscribers.</li>
                        <li>Priority order processing and discounted shipping.</li>
                        <li>You control when you want repeat orders, pause, skip, or cancel at anytime!</li>
                    </ul>
                )} 


                <p className="autoship-modal-footer">
                    <a href="https://autoship.cloud" target="_blank">
                        Flexible subscriptions powered by
                        <span>
                            <img className="autoship-modal-logo" src="https://qpilot.com/wp-content/uploads/2022/03/logo.svg" alt="Qpilot" />
                        </span>
                    </a>
                </p>
            </div>
        </div>
    ); 
}

export default Modal; 